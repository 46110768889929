<template>
    <div>
        <EditConfirmModal :visible="modals.editConfirm" :classEvents="menu.data.class_event" :menuId="menu.data.id"
            @close="modals.editConfirm = false" />
        <div v-if="menu.loading" class="flex items-center space-x-5">
            <Loader class="w-10 h-10 text-green" />
            <p>Loading menu details...</p>
        </div>
        <div v-else-if="menu.data">
            <!-- Heading -->
            <div class="mb-14 flex justify-between">
                <div>
                    <h2 class="mb-2">{{ menu.data.name }}</h2>
                    <div class="flex flex-wrap items-center">
                        <StatusBadge v-if="!menu.data.custom" class="mr-2" :active="isMenuActive(menu.data)" />
                        <p v-else class="mr-2 badge">Custom</p>
                        <p v-if="menu.data.private" class="mr-2 badge">Private</p>
                        <p v-if="menu.data.type" class="inline-block badge mr-2">{{ menu.data.type.name }}</p>
                        <p v-else class="inline-block badge mr-2">Standard</p>
                    </div>
                </div>
                <div v-if="hasEditPermission" class="flex items-center">
                    <button class="menu-item green" @click="editMenu()">
                        <p><i class="fas fa-fw fa-edit mr-2"></i>Edit</p>
                    </button>
                </div>
            </div>

            <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-4 gap-x-4 gap-y-6 mb-14">
                <div>
                    <h6 class="label">Display Name:</h6>
                    <p>{{ menu.data.name }}</p>
                </div>
                <div v-if="!menu.data.custom">
                    <h6 class="label">Month:</h6>
                    <p>{{ formatDate(menu.data.month_year) }}</p>
                </div>
            </div>

            <div class="mb-14">
                <div class="flex justify-between items-center mb-6">
                    <h3><i class="fas fa-fw fa-hamburger mr-2"></i>Meals</h3>
                </div>
                <div class="mb-6">
                    <template v-if="menu.loading">
                        <div class="flex items-center space-x-5">
                            <Loader class="w-10 h-10 text-green" />
                            <p>Loading meals...</p>
                        </div>
                    </template>
                    <template v-else-if="menu.data.meals && menu.data.meals.length">
                        <MealsListView class="mb-10" :meals="this.meals" />
                    </template>
                    <template v-else>
                        <p>
                            <i class="text-xl text-green fas fa-exclamation-triangle mr-3"></i>No
                            meals to show. Please refine your critera.
                        </p>
                    </template>
                </div>
                <template v-if="!menu.data.custom">
                    <div>
                        <div class="flex justify-between items-center mb-2">
                            <h3><i class="fas fa-fw fa-dollar-sign mr-2"></i>Projected Costs</h3>
                        </div>
                        <p class="text-sm mb-4">Note: if the prices do not seem accurate try syncing the meals with Galley.</p>
                    </div>
                    <div class="mb-6">
                        <template v-if="menu.data.meals && menu.data.meals.length">
                            <div class="grid grid-cols-2 gap-4">
                                <div class="col-span-1">
                                    <p class="text-sm font-semibold mb-2">Projected Cost for Regular MEals</p>
                                    <CostListView :selectedMeals="selectedMeals()" :fulfillment="'in-person'" />
                                </div>
                                <div class="col-span-1">
                                    <p class="text-sm font-semibold mb-2">Projected Cost for Bulk Meals</p>
                                    <CostListView :selectedMeals="selectedMeals()" :fulfillment="'delivery'" />
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <p>
                                <i class="text-xl text-green fas fa-exclamation-triangle mr-3"></i>No
                                meals to show. Please refine your critera.
                            </p>
                        </template>
                    </div>
                </template>
            </div>
        </div>
        <div v-else>
            <p>
                <i class="text-xl text-green fas fa-exclamation-triangle mr-2"></i>Whoops... something went wrong!
            </p>
        </div>
    </div>
</template>

<script>
import Loader from "@components/global/Loader";
import StatusBadge from '@components/meals/StatusBadge'
import Multiselect from "vue-multiselect";
import MealsListView from "@components/meals/MenuMealsListView";
import CostListView from '@components/reports/costs/ListView'
import EditConfirmModal from '@components/menus/modals/EditConfirmModal';
import { DateTime } from 'luxon';

export default {
    components: {
        Loader,
        StatusBadge,
        Multiselect,
        MealsListView,
        CostListView,
        EditConfirmModal
    },

    metaInfo() {
        return {
            title: this.menu?.data?.name ? `Menu #${this.menu?.data?.name}` : "Menu Details"
        };
    },

    data() {
        return {
            saving: false,
            menu: {
                loading: false,
                data: [],
            },
            modals: {
                editConfirm: false,
            },
            meals: [],
            statuses: [{ id: 1, name: 'Active', key: 'active', active: true }, { id: 2, name: 'Inactive', key: 'inactive', active: false }],
            status: null,
        };
    },

    methods: {
        async getMenu() {
            this.menu.loading = true;
            try {
                let params = {
                    prices: true,
                }
                const res = await this.$app.api.menus.getMenu(this.$route.params.id, params);
                this.menu.data = res.data;
                this.status = this.menu.data.active ? { id: 1, name: 'Active', key: 'active', active: true } : { id: 0, name: 'Inactive', key: 'inactive', active: false };
                this.meals = this.menu.data.meals;
            } catch (err) {
                console.error(err);
            } finally {
                this.menu.loading = false;
            }
        },
        async activateMenu() {
            let data = {
                active: !this.menu.data.active
            }

            this.menu.loading = true;
            return this.$app.api.menus
                .updateMenu(this.$route.params.id, data)
                .then(res => {
                    this.getMenu();
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => (this.menu.loading = false));
        },
        formatDate(date) {
            return DateTime.fromISO(date).toFormat('MMMM yyyy');
        },
        isMenuActive(menu) {
            const currentMonth = DateTime.now().startOf('month');
            const menuDate = DateTime.fromISO(menu.month_year);
            return currentMonth <= menuDate;
        },
        editMenu() {
            if (this.menu.data.class_event.length == 0) {
                this.$router.push({ name: 'menu-edit', params: { id: this.menu.data.id } });
            } else {
                this.modals.editConfirm = true;
            }
        },
        selectedMeals() {
            return this.menu.data.meals.filter(meal => meal).map(meal => meal.meal);
        },
    },

    computed: {
        hasEditPermission() {
            if (this.menu.data.custom) {
                return this.$app.services.auth.hasPermission('menus.update') || this.$app.services.auth.hasPermission('menus.private.create')
            }
            return this.$app.services.auth.hasPermission('menus.update')
        },
    },

    async created() {
        await this.getMenu();
    }
};
</script>

<style></style>