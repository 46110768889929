<template>
  <nav
    class="h-screen overflow-y-scroll no-scrollbar fixed md:sticky top-0 z-40 translate-x-0 transform-gpu md:transform-none w-64 bg-white border-r border-gray-200 transition-transform"
    :class="{ '-translate-x-64': !open }">
    <div class="h-full px-5">
      <div class="flex items-center justify-between space-x-4 mb-10 px-4">
        <img src="/img/logos/logo.svg" class="w-32 md:mx-auto md:w-10/12 py-4" />
        <div class="w-5 cursor-pointer md:hidden" :class="{ hidden: !open }" @click="close">
          <img src="/img/icons/close.svg" />
        </div>
      </div>
      <div class="flex flex-col space-y-2">
        <router-link v-for="route in allowedRoutes" :key="route.title" :to="route.path" class="block"
          v-slot="{ isActive, isExactActive }" custom>
          <div>
            <router-link :to="route.path">
              <h6 class="px-3 py-1.5" :class="{
                'bg-green text-white rounded': route.exact ? isExactActive : isActive,
              }" v-html="route.title"></h6>
            </router-link>
            <transition name="slide">
              <span v-if="isActive && route.children && route.children.length" class="block">
                <router-link v-for="child in allowedChildRoutes(route.children)" :key="child.title" :to="child.path">
                  <h6 class="pl-10 py-1.5" v-html="child.title"
                    :class="{ underline: $route.path.includes(child.path) }"></h6>
                </router-link>
              </span>
            </transition>
          </div>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      routes: [
        {
          title: '<i class="fas fa-fw fa-columns mr-2"></i>Dashboard',
          path: '/dashboard',
        },
        {
          title: '<i class="fas fa-fw fa-link mr-2"></i>Employee Links',
          path: '/employee-links',
          permissions: ['employee-links.create'],
        },
        {
          title: '<i class="fas fa-fw fa-file-invoice-dollar mr-2"></i>Orders',
          path: '/orders',
          permissions: ['orders.view'],
          children: [],
        },
        {
          title: '<i class="fas fa-fw fa-utensils mr-2"></i>Meals',
          path: '/meals',
          permissions: ['meals.view'],
          children: [],
        },
        {
          title: '<i class="fas fa-fw fa-map mr-2"></i>Menus',
          path: '/menus',
          permissions: ['menus.view'],
          children: [],
        },
        {
          title: '<i class="fas fa-fw fa-lemon mr-2"></i>Events',
          path: '/classes',
          children: [],
        },
        {
          title: '<i class="fas fa-fw fa-hand-holding-heart mr-2"></i>Fulfillments',
          path: '/fulfillments',
          children: [],
        },
        {
          title: '<i class="fas fa-fw fa-shipping-fast mr-2"></i>Shipping',
          path: '/shipping',
          children: [],
        },
        {
          title: '<i class="fas fa-gift mr-2"></i>Gift Cards',
          path: '/gift-cards',
          permissions: [],
          children: [],
        },
        {
          title: '<i class="fas fa-fw fa-tag mr-2"></i>Promotions',
          path: '/promotions',
          permissions: [],
          children: [],
        },
        {
          title: '<i class="fas fa-users mr-2"></i>Users',
          path: '/users',
          permissions: ['users.view'],
          children: [],
        },
        {
          title: '<i class="fas fa-fw fa-pencil-alt mr-2"></i>Blog',
          path: '/blog',
          permissions: [],
          children: [],
        },
        {
          title: '<i class="fas fa-fw fa-newspaper mr-2"></i>Newsroom',
          path: '/newsroom',
          permissions: [],
          children: [
            {
              title: 'Press Releases',
              path: '/newsroom/press-releases',
              permissions: ['newsroom.pressreleases.view'],
            },
            {
              title: 'In the News',
              path: '/newsroom/in-the-news',
              permissions: ['newsroom.newslinks.view'],
            },
          ],
        },
        {
          title: '<i class="fas fa-fw fa-calendar-alt mr-2"></i>Calendars',
          path: '/calendars',
          permissions: [],
          children: [],
        },
        {
          title: '<i class="fas fa-fw fa-map-marker-alt mr-2"></i>Locations',
          path: '/locations',
          permissions: [],
          children: [],
        },
        {
          title: '<i class="fas fa-fw fa-store mr-2"></i>Vendors',
          path: '/vendors',
          permissions: ['locations.create'],
          children: [],
        },
        {
          title: '<i class="fas fa-chart-bar mr-2"></i>Reports',
          path: '/reports',
          permissions: [],
          children: [
            {
              title: 'Projected Menu Costs',
              path: '/reports/menu-costs',
              permissions: [],
            },
            {
              title: 'Delivery Addresses',
              path: '/reports/delivery-addresses',
              permissions: [],
            },
            {
              title: 'Gift Cards',
              path: '/reports/gift-cards',
              permissions: [],
            },
            {
              title: 'Management Payroll',
              path: '/reports/management-payroll',
              permissions: [],
            },
          ],
        },
      ],
    }
  },

  computed: {
    allowedRoutes() {
      return this.routes.filter((route) => {
        if (!route.permissions || route.permissions.length == 0) return true
        return this.$app.services.auth.hasOneOfPermissions(route.permissions)
      })
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },
    allowedChildRoutes(routes) {
      return routes.filter((route) => {
        if (!route.permissions || route.permissions.length == 0) return true
        return this.$app.services.auth.hasOneOfPermissions(route.permissions)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.slide-enter-active {
  animation: openmenu 0.2s cubic-bezier(0.45, -0.15, 0.45, 1.15) 1 normal forwards;
}

.slide-leave-active {
  animation: closemenu 0.2s cubic-bezier(0.45, -0.15, 0.45, 1.15) 1 normal forwards;
}
</style>
