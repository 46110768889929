<template>
  <div>
    <div class="mb-10">
      <h2 class="mb-5">Orders</h2>
      <div class="flex flex-wrap">
        <SearchInput
          class="mr-2"
          v-model="orders.search"
          placeholder="Search by ID, email, name"
          @search="runSearch"
        />
        <DateRangePicker
          class="mr-2"
          :value="orders.dateRange"
          :default="orders.defaultDateRange"
          @input="dateRangeChanged"
        />
        <div class="relative mr-2">
          <div class="flex">
            <!-- <div @click="toggle" class="rounded-md border border-gray bg-white px-3 py-2 cursor-pointer" :class="{'bg-green-600 border-green-600 text-white': visible || rangeSelected}" -->
            <button
              class="rounded-md border border-gray bg-white px-3 py-2 cursor-pointer"
              :class="{
                'bg-green-600 border-green-600 text-white': columns.showOptions,
              }"
              @click.prevent="columns.showOptions = !columns.showOptions"
            >
              <span>Edit Columns</span>
            </button>
          </div>
          <div
            v-show="columns.showOptions"
            class="absolute top-12 max-h-[400px] bg-white overflow-y-auto border border-gray-200 rounded-md px-4 py-2 z-50"
          >
            <div>
              <div v-for="column in columns.data" :key="column.key">
                <Checkbox v-model="column.visible" :label="column.key" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <template v-if="orders.loading">
        <div class="flex items-center space-x-5">
          <Loader class="w-10 h-10 text-green" />
          <p>Loading orders...</p>
        </div>
      </template>
      <template v-else-if="orders.data.data && orders.data.data.length">
        <ListView
          class="mb-10"
          :orders="orders.data.data"
          :visibleColumns="visibleColumns"
        />
        <Pagination
          :page="orders.page"
          :total-items="totalOrders"
          :per-page="orders.perPage"
          @change="pageUpdated"
        />
      </template>
      <template v-else>
        <p>
          <i class="text-xl text-green fas fa-exclamation-triangle mr-3"></i>No orders to
          show. Please refine your critera.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import Loader from '@components/global/Loader'
import SearchInput from '@components/global/SearchInput'
import Pagination from '@components/global/Pagination'
import DateRangePicker from '@components/global/dates/DateRangePicker'
import ListView from '@components/orders/ListView'
import Checkbox from '@components/global/forms/Checkbox'

export default {
  components: {
    Loader,
    SearchInput,
    Pagination,
    ListView,
    DateRangePicker,
    Checkbox,
  },

  metaInfo() {
    return {
      title: this.title,
    }
  },

  data() {
    return {
      orders: {
        loading: false,
        search: this.$route.query.search ?? '',
        page: parseInt(this.$route.query.page) || 1,
        perPage: parseInt(this.$route.query.per_page) || 10,
        dateRange: this.getDateRange(),
        defaultDateRange: this.getDefaultDateRange(),
        data: {},
      },
      columns: {
        showOptions: false,
        data: [
          { key: 'Location', visible: false },
          { key: 'ID', visible: true },
          { key: 'SKU', visible: false },
          { key: 'Customer', visible: true },
          { key: 'Contact', visible: true },
          { key: 'Date', visible: true },
          { key: 'Updated', visible: false },
          { key: 'Payment Method', visible: false },
          { key: 'Tax', visible: false },
          { key: 'Discount', visible: false },
          { key: 'Subtotal', visible: false },
          { key: 'Total', visible: true },
          { key: 'Refunded', visible: false },
          { key: 'Status', visible: true },
          { key: 'Coupon Code', visible: false },
        ],
      },
    }
  },

  computed: {
    title() {
      if (this.$route.query.search) return `Search: ${this.$route.query.search} | Orders`
      return 'Orders'
    },
    totalOrders() {
      return this.orders.data.meta?.total ?? 0
    },
    visibleColumns() {
      return this.columns.data
        .filter((column) => column.visible)
        .map((column) => column.key)
    },
  },

  watch: {
    $route: function (route) {
      this.orders.page = parseInt(route.query.page) || this.orders.page
      this.orders.perPage = parseInt(route.query.per_page) || this.orders.perPage
      this.orders.search = route.query.search ?? this.orders.search
      this.orders.dateRange = this.getDateRange()
      this.getOrders()
    },
  },

  methods: {
    getDefaultDateRange() {
      return {
        start: null,
        end: null,
      }
    },
    getDateRange() {
      let range = this.getDefaultDateRange()

      if (this.$route.query.after_date)
        range.start = DateTime.fromISO(this.$route.query.after_date).startOf('day')
      if (this.$route.query.before_date)
        range.end = DateTime.fromISO(this.$route.query.before_date).endOf('day')

      return range
    },
    dateRangeChanged(input) {
      const range = input.range
      this.orders.dateRange = range
      this.orders.page = 1

      let query = { ...this.$route.query }
      delete query.page

      if (
        +range.start === +this.orders.defaultDateRange.start &&
        +range.end === +this.orders.defaultDateRange.end
      ) {
        delete query.after_date
        delete query.before_date
      } else {
        query.after_date = range.start.toISODate()
        query.before_date = range.end.toISODate()
      }
      this.$router.replace({ path: this.$route.path, query })
      this.getOrders()
    },
    pageUpdated(page) {
      this.orders.page = page
      this.getOrders()
    },
    runSearch() {
      this.orders.page = 1

      let query = { ...this.$route.query, search: this.orders.search }
      delete query.page

      if (!this.orders.search) delete query.search
      this.$router.replace({ path: this.$route.path, query })
      this.getOrders()
    },
    getOrders() {
      let params = {
        page: this.$route.query.page || this.orders.page,
        per_page: this.$route.query.per_page || this.orders.perPage,
      }

      let search = this.$route.query.search || this.orders.search
      if (search) params.search = search

      if (this.orders.dateRange.start)
        params.after_date = this.orders.dateRange.start.toUTC().toISO()
      if (this.orders.dateRange.end)
        params.before_date = this.orders.dateRange.end.toUTC().toISO()

      this.orders.loading = true
      return this.$app.api.orders
        .getOrders(params)
        .then((response) => {
          this.orders.data = response.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => (this.orders.loading = false))
    },
  },

  created() {
    this.getOrders()
  },
}
</script>

<style></style>
