<template>
    <div>
        <div class="hidden xl:grid grid-cols-16 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-light">
            <h6 class="col-span-2 label">Key</h6>
            <h6 class="col-span-2 label">Link</h6>
            <h6 class="col-span-1 label">Views</h6>
            <h6 class="col-span-2 label">Status</h6>
            <h6 class="col-span-2 label">Description</h6>
            <h6 class="col-span-2 label">Valid Dates</h6>
            <h6 class="col-span-2 label">Created By:</h6>
            <h6 class="col-span-1 label">Uses</h6>
            <h6 class="col-span-1 label">Fulfillment</h6>
            <h6 class="col-span-1 label"></h6>
        </div>
        <div class="xl:divide-y xl:divide-gray-light xl:border xl:border-t-0 border-gray-200 xl:border-gray-light rounded-b-md">
            <div v-for="code in codes" :key="code.id" class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-3 xl:grid-cols-16 gap-2 xl:px-2">
                    <div class="xl:col-span-2 overflow-hidden">
                        <h6 class="label xl:hidden">Key</h6>
                        <h5 class="break-words">{{ code.key }}</h5>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label xl:hidden">Link</h6>
                        <p class="break-words"><a v-if="code.link" :href="code.link">{{ code.link }}</a><span v-else>-</span></p>
                    </div>
                    <div class="xl:col-span-1">
                        <h6 class="label xl:hidden">Views</h6>
                        <p>{{ code.link_views }}</p>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label xl:hidden">Status</h6>
                        <p><StatusBadge class="text-xs" :code="code"/></p>
                        <p v-if="code.sitewide"><span class="badge pink text-xs">Sitewide</span></p>
                        <p v-if="code.affiliate"><span class="badge text-xs">Affiliate</span></p>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label xl:hidden">Description</h6>
                        <p>{{ code.description }}</p>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label xl:hidden">Valid Dates</h6>
                        <template v-if="code.start">
                            <p><Date :date="code.start" preset="DATE_MED"/>-</p>
                            <p><Date :date="code.end" preset="DATE_MED"/></p>
                        </template>
                        <template v-else>
                            <p>Always</p>
                        </template>
                    </div>
                    <div class="xl:col-span-2">
                        <h6 class="label xl:hidden">Created By:</h6>
                        <p>{{code.created_by && code.created_by.first_name}} {{code.created_by && code.created_by.last_name}}{{ !code.created_by ? "Unknown" : "" }}, </p>
                        <p><Date :date="code.created_at" preset="DATE_MED"/></p>
                    </div>
                    <div class="xl:col-span-1">
                        <h6 class="label xl:hidden">Uses</h6>
                        <p>{{ code.uses }}/{{ code.max_uses || "-" }}</p>
                    </div>
                    <div class="xl:hidden">
                        <h6 class="label xl:hidden">Max Uses Per User</h6>
                        <p>{{ code.max_uses_per_user }}</p>
                    </div>
                    <div class="xl:col-span-1">
                        <h6 class="label xl:hidden">Fulfillment</h6>
                        <p>{{ getFulfillment(code) }}</p>
                    </div>
                    <div class="xl:col-span-1">
                        <div class="flex flex-row xl:flex-col gap-1 xl:items-end">
                            <button v-if="canEditCodes" class="btn xs w-8 !px-0 h-6" :disabled="!canEditCodes" @click="$emit('edit', code)"><i class="ml-[3px] far fa-edit"></i></button>
                            <button v-if="canDeleteCodes" class="btn xs gray w-8 !px-0 h-6" :disabled="!canDeleteCodes" @click="$emit('delete', code)"><i class="fa fa-trash" aria-hidden="true"></i>
</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Date from '@components/global/dates/Date'
import StatusBadge from '@components/promotions/codes/StatusBadge'

export default {
    name: "CodesListView",

    props: {
        codes: {
            type: Array,
            required: true
        }
    },

    components: {
        Date,
        StatusBadge
    },

    computed: {
        canEditCodes() {
            return this.$app.services.auth.hasOneOfPermissions(['promotions.codes.update'])
        },
        canDeleteCodes() {
            return this.$app.services.auth.hasOneOfPermissions(['promotions.codes.delete'])
        }
    },

    methods: {
        getFulfillment(code) {
            switch (code.fulfillment?.key) {
                case 'delivery':
                    return 'Delivery'
                case 'in-person':
                    return 'Classes'
                case 'pre-assembled':
                    return 'Pickup'
                default:
                    return 'All'
            }
        }
    },
}
</script>

<style scoped>

</style>
