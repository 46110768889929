<template>
    <div>
        <div class="hidden xl:grid grid-cols-12 gap-2 p-2 bg-gray-100 rounded-t-md border border-gray-200">
            <h6 class="col-span-3 label">Name</h6>
            <h6 class="col-span-3 label">Month</h6>
            <h6 class="col-span-3 label">Type</h6>
            <h6 class="col-span-3 label">Active</h6>
        </div>
        <div class="xl:divide-y xl:divide-gray-200 xl:border xl:border-t-0 border-gray-200 xl:border-gray-200 rounded-b-md">
            <div v-for="menu in menus" :key="menu.id"
                class="bg-gray-100 mb-5 xl:mb-0 rounded-md xl:rounded-none xl:bg-transparent p-5 xl:px-0 xl:py-2">
                <div class="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-12 gap-2 gap-y-4 xl:px-3">
                    <div class="xl:col-span-3">
                        <h6 class="label xl:hidden">Menu</h6>
                        <router-link class="hover:underline block mr-2" :to="`/menus/${menu.id}`">
                            <p>{{ menu.name }}</p>
                        </router-link>
                    </div>
                    <div class="xl:col-span-3">
                        <h6 class="label xl:hidden">Month</h6>
                        <p v-if="menu.month_year">{{ formatDate(menu.month_year) }}</p>
                        <p v-else class="mr-2"><span class="badge small">Custom Menu</span></p>
                    </div>
                    <div class="xl:col-span-3">
                        <h6 class="label xl:hidden">Type</h6>
                        <p v-if="menu.type" class="inline-block small badge mr-2">{{ menu.type.name }}</p>
                        <p v-else class="inline-block small badge mr-2">Standard</p>
                    </div>
                    <div class="xl:col-span-3">
                        <h6 class="label xl:hidden">Active</h6>
                        <StatusBadge :active="isMenuActive(menu)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import StatusBadge from '@components/meals/StatusBadge'

import { DateTime } from 'luxon';

export default {
    props: {
        menus: {
            type: Array,
            required: true
        }
    },
    components: {
        StatusBadge,
    },
    methods: {
        formatDate(date) {
            return DateTime.fromISO(date).toFormat('MMMM yyyy');
        },
        isMenuActive(menu) {
            const currentMonth = DateTime.now().startOf('month');
            const menuDate = DateTime.fromISO(menu.month_year);
            return currentMonth <= menuDate;
        },
    }
};
</script>
  
<style></style>
  