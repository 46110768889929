<template>
    <div class="md:grid grid-cols-1 gap-4">
        <!-- Active -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Status:</label>
            <Checkbox v-model="$v.location.active.$model" label="Active" />
        </div>

        <!-- Location Name -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Location Name*:</label>
            <input v-model="$v.location.name.$model" type="text" class="cp-input w-full lg:w-3/12 mb-0"
                :class="{ 'cp-input-error': $v.location.name.$error }">
            <template v-if="$v.location.name.$error">
                <p v-if="!$v.location.name.required" class="text-red text-xs ml-1">Location name is required</p>
            </template>
        </div>

        <!-- Abbreviation -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">2-3 Character Abbreviation*:</label>
            <input v-model="$v.location.code.$model" v-mask="'AAA'" type="text" class="cp-input w-full lg:w-1/12 mb-0"
                :class="{ 'cp-input-error': $v.location.code.$error }">
            <template v-if="$v.location.code.$error">
                <p v-if="!$v.location.code.required" class="text-red text-xs ml-1">Abbreviation is required</p>
                <p v-if="!$v.location.code.maxLength" class="text-red text-xs ml-1">Abbreviation must be no more than 3
                    characters</p>
            </template>
        </div>

        <!-- Store Name -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Store Name*:</label>
            <input v-model="$v.location.store_name.$model" type="text" class="cp-input w-full lg:w-3/12 mb-0"
                :class="{ 'cp-input-error': $v.location.store_name.$error }">
            <template v-if="$v.location.store_name.$error">
                <p v-if="!$v.location.store_name.required" class="text-red text-xs ml-1">Store name is required</p>
            </template>
        </div>

        <!-- Timezone -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Timezone:</label>
            <select v-model="$v.location.timezone.$model" name="timezone"
                class="rounded-md border border-gray bg-white w-full lg:w-3/12 px-3 py-2 mb-0"
                :class="{ 'cp-input-error': $v.location.store_name.$error }">
                <option v-for="(zone, i) in timezones" :value="zone" :selected="zone === 'America/Denver'"
                    :key="`${i}-${zone}`">{{ zone }}</option>
            </select>
            <template v-if="$v.location.timezone.$error">
            </template>
        </div>

        <!-- Lead Name -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Lead Name*:</label>
            <input v-model="$v.location.lead_name.$model" type="text" class="cp-input w-full lg:w-3/12 mb-0"
                :class="{ 'cp-input-error': $v.location.lead_name.$error }">
            <template v-if="$v.location.lead_name.$error">
                <p v-if="!$v.location.lead_name.required" class="text-red text-xs ml-1">Lead Name is required</p>
            </template>
        </div>

        <!-- Lead Email -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Lead Email*:</label>
            <input v-model.lazy="$v.location.lead_email.$model" type="email" class="cp-input w-full lg:w-3/12 mb-0"
                :class="{ 'cp-input-error': $v.location.lead_email.$error }">
            <template v-if="$v.location.lead_email.$error">
                <p v-if="!$v.location.lead_email.required" class="text-red text-xs ml-1">Lead Email is required</p>
                <p v-if="!$v.location.lead_email.email" class="text-red text-xs ml-1">Lead Email must be formatted
                    correctly</p>
            </template>
        </div>

        <!-- Regional Lead Name -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Regional Lead Name*:</label>
            <input v-model="$v.location.regional_name.$model" type="text" class="cp-input w-full lg:w-3/12 mb-0"
                :class="{ 'cp-input-error': $v.location.regional_name.$error }">
            <template v-if="$v.location.regional_name.$error">
                <p v-if="!$v.location.regional_name.required" class="text-red text-xs ml-1">Regional Lead Name is
                    required</p>
            </template>
        </div>

        <!-- Regional Lead Email -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Regional Lead Email*:</label>
            <input v-model.lazy="$v.location.regional_email.$model" type="email" class="cp-input w-full lg:w-3/12 mb-0"
                :class="{ 'cp-input-error': $v.location.regional_email.$error }">
            <template v-if="$v.location.regional_email.$error">
                <p v-if="!$v.location.regional_email.required" class="text-red text-xs ml-1">Regional Lead Email is
                    required</p>
                <p v-if="!$v.location.regional_email.email" class="text-red text-xs ml-1">Regional Lead Email must be
                    formatted correctly</p>
            </template>
        </div>

        <!-- Google Drive FolderID -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Google Drive FolderID:</label>
            <input v-model="$v.location.google_drive_folder.$model" type="text" class="cp-input w-full lg:w-5/12 mb-0"
                :class="{ 'cp-input-error': $v.location.google_drive_folder.$error }">
            <template v-if="$v.location.google_drive_folder.$error">
            </template>
        </div>

        <!-- Default Class Size -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Default Class Size*:</label>
            <input v-model="$v.location.default_class_size.$model" type="text" class="cp-input w-full lg:w-1/12 mb-0"
                :class="{ 'cp-input-error': $v.location.default_class_size.$error }">
            <template v-if="$v.location.default_class_size.$error">
                <p v-if="!$v.location.default_class_size.required" class="text-red text-xs ml-1">Default Class Size is
                    required</p>
                <p v-if="!$v.location.default_class_size.numeric" class="text-red text-xs ml-1">Default Class Size must
                    be a positive integer</p>
            </template>
        </div>

        <!-- Couple Class Size -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Couple Class Size*:</label>
            <input v-model="$v.location.couple_class_size.$model" type="text" class="cp-input w-full lg:w-1/12 mb-0"
                :class="{ 'cp-input-error': $v.location.couple_class_size.$error }">
            <template v-if="$v.location.couple_class_size.$error">
                <p v-if="!$v.location.couple_class_size.required" class="text-red text-xs ml-1">Couple Class Size is
                    required</p>
                <p v-if="!$v.location.couple_class_size.numeric" class="text-red text-xs ml-1">Couple Class Size must be
                    a positive integer</p>
            </template>
        </div>

        <!-- Pickup Class size -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Pickup Class Size:</label>
            <input v-model="$v.location.pickup_class_size.$model" type="text" class="cp-input w-full lg:w-1/12 mb-0"
                :class="{ 'cp-input-error': $v.location.pickup_class_size.$error }">
            <template v-if="$v.location.pickup_class_size.$error">
                <p v-if="!$v.location.pickup_class_size.numeric" class="text-red text-xs ml-1">Pickup Class Size must be
                    a positive integer</p>
            </template>
        </div>

        <!-- Allow Custom Class -->
        <div class="col-span-1">
            <Checkbox v-model="$v.location.allow_custom_class.$model" label="Allow Custom Class" />
        </div>

        <!-- Grocery Etrog Note -->
        <div class="col-span-1">
            <Textbox v-model="$v.location.grocery_etrog_note.$model" class="w-full lg:w-5/12"
                :class="{ 'cp-input-error': $v.location.grocery_etrog_note.$error }" label="Grocery Etrog Note*:"
                placeholder="" />
            <template v-if="$v.location.grocery_etrog_note.$error">
                <p v-if="!$v.location.grocery_etrog_note.required" class="text-red text-xs ml-1">Grocery Etrog Note is
                    required</p>
            </template>
        </div>

        <!-- Meat Etrog Note -->
        <div class="col-span-1">
            <Textbox v-model="$v.location.meat_etrog_note.$model" class="w-full lg:w-5/12"
                :class="{ 'cp-input-error': $v.location.meat_etrog_note.$error }" label="Meat Etrog Note*:"
                placeholder="" />
            <template v-if="$v.location.meat_etrog_note.$error">
                <p v-if="!$v.location.meat_etrog_note.required" class="text-red text-xs ml-1">Meat Etrog Note is
                    required</p>
            </template>
        </div>

        <!-- Price Modifier -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Price Modifier:</label>
            <input v-model.lazy="$v.location.price_modifier.$model" v-money="{ precision: 2, thousands: '' }"
                type="text" class="cp-input w-full lg:w-1/12 mb-0"
                :class="{ 'cp-input-error': $v.location.price_modifier.$error }">
            <template v-if="$v.location.price_modifier.$error">
                <p v-if="!$v.location.price_modifier.decimal || !$v.location.price_modifier.minValue"
                    class="text-red text-xs ml-1">Price Modifier must be a positive number</p>
            </template>
        </div>

        <!-- Pricing Offset -->
        <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Pricing Offset:</label>
            <input v-model.lazy="$v.location.surcharge.$model" v-money="{ precision: 2, thousands: '' }" type="text"
                class="cp-input w-full lg:w-1/12 mb-0" :class="{ 'cp-input-error': $v.location.surcharge.$error }">
            <template v-if="$v.location.surcharge.$error">
                <p v-if="!$v.location.surcharge.decimal" class="text-red text-xs ml-1">Pricing Offset must be a number
                </p>
            </template>
        </div>

        <!-- Delivery Zipcodes -->
        <div class="col-span-1">
            <Textbox class="mb-2" v-model="deliveryZipcodesText" label="Delivery Zipcodes:"
                placeholder="Enter zip codes separated by commas" />
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">Bulk import zipcodes (.csv or .txt):</label>
            <input type="file" @change="handleFileUpload" accept=".csv, .txt" class="mb-2" />
        </div>

        <!-- Vendors -->
        <div class="col-span-1 mb-6">
            <label class="block text-xs font-body text-gray-dark mb-2">Vendor:</label>
            <multiselect class="w-5/12 mb-2 multiselect" v-model="location.location_vendor" :options="vendors.data"
                label="name" track-by="id" :multiple="false" :close-on-select="true">
                <template slot="placeholder"><span>Select vendor</span></template>
                <template slot="caret" slot-scope="{ toggle }">
                    <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                        <img class="absolute top-1/2 right-1/2 translate-x-[50%] translate-y-[-50%] select-none"
                            src="/img/icons/down-chevron.svg" alt="icon" />
                    </div>
                </template>
            </multiselect>
        </div>

        <!-- Store Address -->
        <div>
            <h4><i class="fas fa-fw fa-map-marker-alt mr-2"></i>Store Location</h4>
            <GoogleMapLocator :location="location" :updateLocation="updateLocation" :viewMap="false" />
            <template v-if="$v.location.$invalid">
                <p v-if="addressHasError" class="text-red text-base ml-1 mt-4">The address is invalid. Please reselect
                    the address.</p>
            </template>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { zones } from 'tzdata'
import { decimal, email, maxLength, minValue, numeric, required } from 'vuelidate/lib/validators'
import Checkbox from '@components/global/forms/Checkbox'
import Textbox from '@/components/global/forms/Textbox'
import GoogleMapLocator from '@components/locations/GoogleMapLocator'

export default {
    name: 'LocationForm',

    components: {
        Checkbox,
        Textbox,
        GoogleMapLocator,
    },

    props: {
        value: {
            default: null
        }
    },

    data() {
        return {
            location: {
                name: null,
                store_name: null,
                street_1: null,
                street_2: null,
                city: null,
                state: null,
                postal_code: null,
                country: null,
                active: true,
                price_modifier: '1.00',
                surcharge: '0.00',
                default_class_size: null,
                couple_class_size: null,
                pickup_class_size: null,
                code: null,
                timezone: 'America/Denver',
                lead_name: null,
                lead_email: null,
                regional_name: null,
                regional_email: null,
                google_drive_folder: null,
                grocery_etrog_note: null,
                meat_etrog_note: null,
                lat: null,
                long: null,
                allow_custom_class: false,
                delivery_zipcodes: [],
                location_vendor: null,
            },
            vendors: {
                loading: false,
                data: [],
            },
        }
    },

    methods: {
        emitLocation() {
            this.$emit('input', { location: this.location, isValid: this.isValid })
        },
        updateLocation(location) {
            let street_1 = location.street_number
            street_1 += location.route ? ' ' + location.route : ''

            this.location.street_1 = street_1
            this.location.street_2 = location.subpremise
            this.location.city = location.locality
            this.location.state = location.administrative_area_level_1
            this.location.postal_code = location.postal_code
            this.location.country = location.country
            this.location.lat = location.lat
            this.location.long = location.lng
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const contents = e.target.result;
                    this.processCSV(contents);
                };
                reader.readAsText(file);
            }
        },
        processCSV(contents) {
            const rows = contents.split('\n');
            const newZips = rows.map(row => row.trim()).filter(zip => this.isValidZipcode(zip));

            const currentZips = this.location.delivery_zipcodes.map(zipObj => zipObj.zipcode);
            const combinedZips = [...new Set([...currentZips, ...newZips])];

            this.location.delivery_zipcodes = combinedZips.map(zip => ({ zipcode: zip }));
        },
        isValidZipcode(zip) {
            const zipCodePattern = /^\d{5}(-\d{4})?$/;
            return zipCodePattern.test(zip);
        },
        getVendors() {
            this.vendors.loading = true
            this.$app.api.locations.getVendors()
                .then(response => {
                    this.vendors.data = response.data
                })
                .catch(error => {
                    this.$app.error.handle(error)
                })
                .finally(() => {
                    this.vendors.loading = false
                })
        },
    },

    computed: {
        deliveryZipcodesText: {
            get() {
                return this.location.delivery_zipcodes.map(zip => zip.zipcode).join(', ');
            },
            set(value) {
                this.location.delivery_zipcodes = value.split(',').map(zip => ({ zipcode: zip.trim() }));
            }
        },
        isValid() {
            return !this.$v.location.$invalid
        },
        timezones() {
            return Object.keys(zones).sort()
        },
        addressHasError() {
            return this.$v.location.street_1.$invalid || this.$v.location.city.$invalid || this.$v.location.state.$invalid || this.$v.location.postal_code.$invalid
        },
    },

    watch: {
        location: {
            handler(value) {
                this.debouncedEmit()
            },
            deep: true
        }
    },

    created() {
        this.debouncedEmit = _.debounce(this.emitLocation, 500)

        if (this.value) this.location = this.value

        this.getVendors()
    },

    validations: {
        location: {
            name: { required },
            store_name: { required },
            street_1: { required },
            street_2: {},
            city: { required },
            state: { required },
            postal_code: { required },
            country: {},
            active: { required, checked: val => val === true || val === false },
            price_modifier: { decimal, minValue: minValue(0) },
            surcharge: { decimal },
            default_class_size: { required, numeric },
            couple_class_size: { required, numeric },
            pickup_class_size: { numeric },
            code: { required, maxLength: maxLength(3) },
            timezone: {},
            lead_name: { required },
            lead_email: { required, email },
            regional_name: { required },
            regional_email: { required, email },
            google_drive_folder: {},
            grocery_etrog_note: { required },
            meat_etrog_note: { required },
            lat: { decimal },
            long: { decimal },
            allow_custom_class: { required, checked: val => val === true || val === false },
            delivery_zipcodes: {},
            location_vendor: { required },
        },
    }
}
</script>

<style lang="scss" scoped></style>
