<template>
    <div class="flex flex-col p-6 w-full">
        <div class="mb-8 flex justify-between space-x-3">
            <h3 v-if="id">Edit Menu</h3>
            <h3 v-else>Create Menu</h3>
        </div>

        <MenuForm v-model="menu" :menuId="id" />

        <div v-if="error" class="flex space-x-5 mb-4">
            <p class="text-red ml-1">{{ error }}</p>
        </div>

        <div class="flex space-x-4 mt-6 ml-1">
            <router-link v-if="id" class="btn white px-6" :to="`/menus/${id}`">Cancel</router-link>
            <router-link v-else class="btn white px-6" to="/menus">Cancel</router-link>
            <button class="btn button-green px-6 flex items-center" @click="handleSave"
                :disabled="!canCreateMenu || saving">
                <span v-if="saving" class="mr-2">
                    <Loader class="w-5 h-5 text-white" />
                </span>
                <span v-if="id">Update</span>
                <span v-else>Save</span>
            </button>
        </div>
    </div>
</template>

<script>
import MenuForm from '@components/menus/MenuForm'
import Loader from '@components/global/Loader'

export default {
    name: "CreateMenu",

    components: {
        MenuForm,
        Loader
    },

    props: {
        id: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            saving: false,
            menu: null,
            error: null,
        }
    },

    methods: {
        createMenu() {
            let data = {
                month_year: this.menu.month_year.id,
                meal_type_id: this.menu.type.id != 1 ? this.menu.type.id : null,
                custom: false,
                meals: this.menu.selections
                    .filter(selection => selection.meal != null)
                    .map(selection => {
                        return {
                            meal_id: selection.meal.id,
                            delivery_only: selection.delivery,
                        }
                    }),
            };

            this.saving = true
            this.$app.api.menus.createMenu(data)
                .then((response) => {
                    this.$router.push('/menus')
                })
                .catch((error) => {
                    console.error(error)
                    this.error = error.response && error.response.data && error.response.data.message
                        ? error.response.data.message
                        : 'Unknown error.';
                })
                .finally(() => {
                    this.saving = false
                })
        },
        updateMenu() {
            let data = {
                meals: this.menu.selections
                    .filter(selection => selection.meal != null)
                    .map(selection => {
                        return {
                            meal_id: selection.meal.id,
                            delivery_only: selection.delivery,
                        }
                    }),
            };

            this.saving = true
            this.$app.api.menus.updateMenu(this.id, data)
                .then((response) => {
                    this.$router.push(`/menus/${this.id}`)
                })
                .catch((error) => {
                    console.error(error)
                    this.error = error.response && error.response.data && error.response.data.message
                        ? error.response.data.message
                        : 'Unknown error.';
                })
                .finally(() => {
                    this.saving = false
                })
        },
        handleSave() {
            if (this.id) {
                this.updateMenu()
            } else {
                this.createMenu()
            }
        },
    },

    computed: {
        canCreateMenu() {
            return !!this.menu && (this.$app.services.auth.hasPermission('menus.create') || this.$app.services.auth.hasPermission('menus.private.create'))
        }
    },

}
</script>