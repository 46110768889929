import { render, staticRenderFns } from "./GroceryOrderModal.vue?vue&type=template&id=68d33988&scoped=true&"
import script from "./GroceryOrderModal.vue?vue&type=script&lang=js&"
export * from "./GroceryOrderModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68d33988",
  null
  
)

export default component.exports