<template>
  <Modal :visible="visible">
    <div class="flex flex-col bg-white rounded-md p-6 w-full max-w-screen-xs">
      <div class="mb-8 flex justify-between space-x-3">
        <h3>Create Promotion Codes</h3>
        <button v-if="!newCodes" class="px-2 text-xl text-green" @click="close">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="grid grid-cols-1 gap-4 mb-10">
        <template v-if="!newCodes">
          <div v-if="error" class="flex space-x-5 mb-4">
            <p class="text-red ml-1">{{ error }}</p>
          </div>
          <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1"
              >*Count:</label
            >
            <input
              v-model="$v.codes.count.$model"
              type="number"
              class="cp-input mb-0"
              :class="{ 'cp-input-error': $v.codes.count.$error }"
            />
            <template v-if="$v.codes.count.$error">
              <p v-if="!$v.codes.count.required" class="text-red text-xs ml-1">
                Count is required
              </p>
            </template>
          </div>
          <div v-if="$v.codes.count.$model === '1'" class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1">*Key:</label>
            <input
              v-model="$v.codes.key.$model"
              type="text"
              class="cp-input mb-0"
              :class="{ 'cp-input-error': $v.codes.key.$error }"
            />
            <template v-if="$v.codes.key.$error">
              <p v-if="!$v.codes.key.required" class="text-red text-xs ml-1">
                Key is required
              </p>
              <p v-if="!$v.codes.key.required" class="text-red text-xs ml-1">
                Key accepts only alpha numeric characters
              </p>
            </template>
          </div>
          <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1"
              >Status:</label
            >
            <Checkbox v-model="$v.codes.enabled.$model" label="Enabled" />
            <Checkbox v-model="$v.codes.sitewide.$model" label="Sitewide" />
            <Checkbox v-model="$v.codes.affiliate.$model" label="Affiliate" />
          </div>
          <div class="col-span-1">
            <div class="flex">
              <DateRangePicker
                class="mr-2"
                label="Dates:"
                :time="true"
                :value="codes.dateRange"
                :default="defaultDateRange"
                @input="dateRangeChanged"
              />
            </div>
          </div>
          <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1"
              >Max Uses:</label
            >
            <input
              v-model="$v.codes.max_uses.$model"
              type="number"
              class="cp-input mb-0"
              :class="{ 'cp-input-error': $v.codes.max_uses.$error }"
            />
            <template v-if="$v.codes.max_uses.$error"> </template>
          </div>
          <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1"
              >Max Uses Per User:</label
            >
            <input
              v-model="$v.codes.max_uses_per_user.$model"
              type="number"
              class="cp-input mb-0"
              :class="{ 'cp-input-error': $v.codes.max_uses_per_user.$error }"
            />
            <template v-if="$v.codes.max_uses_per_user.$error"> </template>
          </div>
          <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1"
              >Allowed Fulfillment:</label
            >
            <multiselect class="max-w-xs ml-1" v-model="$v.codes.fulfillment_type.$model" :options="fulfillmentTypes"
              :close-on-select="true" :show-labels="false" :allow-empty="false"
              label="name" track-by="id"
              placeholder="Select fulfillment type">
              <template slot="placeholder"><span class="text-gray-400">Select fulfillment type</span></template>

              <template #caret="{ toggle }">
                <div class="multiselect__select" @mousedown.prevent.stop="toggle">
                  <span class="absolute right-3 top-3 select-none text-green"><i class="fas fa-chevron-down"></i></span>
                </div>
              </template>
            </multiselect>
          </div>
          <div class="col-span-1">
            <label class="block text-xs font-body text-gray-dark mb-2 ml-1"
              >Show in Registration Notes:</label
            >
            <Checkbox v-model="$v.codes.show_in_notes.$model" label="Show" />
          </div>
          <div class="col-span-1">
            <Textbox
              v-model="$v.codes.description.$model"
              :class="{ 'cp-input-error': $v.codes.description.$error }"
              label="Description:"
              placeholder=""
            />
            <template v-if="$v.codes.description.$error"> </template>
          </div>
        </template>
        <template v-else>
          <div class="flex justify-between space-x-3">
            <h3>Created Codes</h3>
          </div>
          <template v-if="newCodes.length > 15">
            <a :href="downloadLink()" target="_blank"
              ><i class="fas fa-fw fa-download mr-2"></i>Download Codes</a
            >
          </template>
          <p>Created {{ newCodes.length }} codes.</p>
          <template v-if="newCodes.length < 20">
            <ul>
              <li v-for="code in newCodes">{{ code.key }}</li>
            </ul>
          </template>
          <template v-else>
            <p>Displaying only the first 20 codes.</p>
            <p>Please download for a complete list of the new codes.</p>
            <ul>
              <li v-for="code in newCodes.slice(0, 20)">{{ code.key }}</li>
              <li>. . .</li>
            </ul>
          </template>
        </template>
      </div>
      <div class="flex space-x-5">
        <template v-if="newCodes">
          <button class="btn white px-6" @click="update">Back To Promotion</button>
        </template>
        <template v-else>
          <button class="btn white px-6" @click="close" :disabled="saving">Cancel</button>
          <button
            class="btn button-green px-6"
            @click="saveCode"
            :disabled="!canCreateCodes || saving"
          >
            <div v-if="saving" class="flex items-center space-x-1">
              <Loader class="w-5 h-5" /><span>Saving</span>
            </div>
            <span v-else-if="success"
              ><i class="fas fa-check-circle mr-1"></i>Saved!</span
            >
            <span v-else>Save</span>
          </button>
        </template>
      </div>
    </div>
  </Modal>
</template>

<script>
import { requiredIf, alphaNum } from 'vuelidate/lib/validators'
import Modal from '@components/global/modals/Modal'
import DateRangePicker from '@components/global/dates/DateRangePicker'
import Textbox from '@components/global/forms/Textbox'
import Radio from '@components/global/forms/Radio'
import Loader from '@components/global/Loader'
import Checkbox from '@components/global/forms/Checkbox'

export default {
  name: 'CreatePromotionCodeModal',

  components: {
    Radio,
    Modal,
    DateRangePicker,
    Textbox,
    Loader,
    Checkbox,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      error: null,
      saving: false,
      success: false,
      codes: this.getCodesDefault(),
      defaultDateRange: this.getDefaultDateRange(),
      newCodes: null,
      fulfillmentTypes: [
        { id: 0, name: 'All', key: 'all'},
        { id: 1, name: 'In-person Preparation Class', key: 'in-person' },
        { id: 2, name: 'Pre-assembled Meals', key: 'pre-assembled' },
        { id: 3, name: 'Delivery', key: 'delivery' },
      ],
    }
  },

  computed: {
    isValid() {
      return !this.$v.codes.$invalid
    },
    hasPermissions() {
      return this.$app.services.auth.hasPermission('promotions.codes.create')
    },
    canCreateCodes() {
      return this.isValid && this.hasPermissions
    },
    promotionId() {
      return parseInt(this.$route.params.id)
    },
  },

  methods: {
    close() {
      this.error = null
      this.newCodes = null
      this.codes = this.getCodesDefault()
      this.$emit('close')
    },
    update() {
      this.close()
      this.$emit('update')
    },
    saveCode() {
      let data = {
        key: this.codes.count === '1' ? this.codes.key : null,
        count: this.codes.count,
        start_date: this.codes.dateRange.start
          ? this.codes.dateRange.start.toUTC()
          : null,
        expiration_date: this.codes.dateRange.end
          ? this.codes.dateRange.end.toUTC()
          : null,
        description: this.codes.description,
        max_uses: this.codes.max_uses,
        max_uses_per_user: this.codes.max_uses_per_user,
        enabled: this.codes.enabled,
        sitewide: this.codes.sitewide,
        affiliate: this.codes.affiliate,
        show_in_notes: this.codes.show_in_notes,
        fulfillment_id: this.codes.fulfillment_type.id,
      }

      this.error = null
      this.saving = true
      this.$app.services.promotion
        .createPromotionCodes(this.promotionId, data)
        .then((response) => {
          this.success = true
          setTimeout(() => {
            this.newCodes = response.data
            // this.success = false
            // this.$router.push(`/promotions/${this.promotionId}`)
          }, 1000)
        })
        .catch((error) => {
          this.error = error?.response?.data?.message
        })
        .finally(() => {
          this.saving = false
        })
    },
    dateRangeChanged(input) {
      this.codes.dateRange = input.range
    },
    getDefaultDateRange() {
      return {
        start: null,
        end: null,
      }
    },
    getCodesDefault() {
      return {
        max_uses: null,
        max_uses_per_user: 1,
        enabled: true,
        sitewide: false,
        affiliate: false,
        show_in_notes: false,
        description: null,
        dateRange: this.getDefaultDateRange(),
        count: null,
        key: null,
        fulfillment_type: { id: 0, name: 'All', key: 'all' },
      }
    },
    downloadLink() {
      let ids = this.newCodes
        .map((code) => {
          return code.id
        })
        .join('|')
      return `${process.env.VUE_APP_API_BASE_URL}/promotions/${this.promotionId}/codes/download?ids=${ids}`
    },
  },

  validations: {
    codes: {
      start_date: {},
      expiration_date: {},
      max_uses: {},
      max_uses_per_user: {},
      enabled: {},
      sitewide: {},
      affiliate: {},
      show_in_notes: {},
      description: {},
      count: {
        required: requiredIf((form) => {
          return form.count > '1' || form.count === null
        }),
      },
      key: {
        required: requiredIf((form) => {
          return form.count === '1'
        }),
        // alphaNum,
      },
      fulfillment_type: {},
    },
  },
}
</script>

<style lang="scss" scoped></style>
